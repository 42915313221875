import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviourService, mapAttachments } from '@pa/lib-spa';
import { Attachment, Behaviour, FormatDateConfig } from '@pa/lib-spa/types';
import { FeatureFlags, OriginatorType, TransactionType } from '@pa/references/idf';
import { ClientPolicy } from '@pa/sdk/cmf';
import { Organisation, Originator } from '@pa/sdk/idf';
import { PaClient } from '@pa/sdk/rmf';
import { Auth } from 'aws-amplify';
import { Subject, forkJoin, from, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../components/dialogs/confirm-dialog.component';
import { mapDocumentationLinks } from '../core/utils/attachment.mapping';
import { PolicyService } from '../services/policy.service';
import { QuoteService } from '../services/quote.service';
import { SdkService } from '../services/sdk.service';
import { AuthService } from './../core/auth/auth.service';
import { PolicyState } from '@pa/references/cmf';
import { Location } from '@angular/common';
import { getTransactionType } from '../utils/common';

@Component({
    selector: 'app-view-policy',
    templateUrl: './view-policy.component.html',
    styleUrls: ['./view-policy.component.scss'],
})
export class ViewPolicyComponent implements OnInit, OnDestroy {
    @ViewChild('confirmDialog') confirmDialog: ConfirmDialogComponent;
    @ViewChild('reassignBrokerDialog') reassignBrokerDialog: ConfirmDialogComponent;
    @ViewChild('responseDialog') responseDialog: ConfirmDialogComponent;

    attachments: Attachment[];
    behaviour: Behaviour;
    behaviourId: string = '';
    cancellationDate: string;
    clientPolicy: ClientPolicy;
    enabledFeatureFlags: FeatureFlags[];
    errorMessage: string = '';
    organisation: Organisation;
    organisationOriginators: Originator[] = [];
    originator: Originator;
    paClient: PaClient;
    policyId: string = '';
    selectedOriginatorCode: string;
    serviceActionEvent?: FeatureFlags;
    timezone: string = 'UTC';
    policyDetails: { [date in string]: FormatDateConfig } = {};

    error: boolean = false;
    hideReassignBrokerLink: boolean = true;
    hideRegenerateLink: boolean = true;
    hideResendLink: boolean = true;
    hideProcessCancelLink: boolean = true;
    loading: boolean = true;
    loadingAction: boolean = true;
    loadingDetails: boolean = false;
    showInceptionDate: boolean = true;
    currentUser: string = '';
    cancelStatus: boolean = false;

    private destroy$ = new Subject();

    constructor(
        private location: Location,
        private behaviourService: BehaviourService,
        private policyService: PolicyService,
        private quoteService: QuoteService,
        private router: ActivatedRoute,
        private sdkService: SdkService,
        private authService: AuthService
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }

    ngOnInit(): void {
        this.loading = true;
        this.error = false;

        Auth.currentSession()
            .then((res) => res.getAccessToken().getJwtToken())
            .then((accessToken) => {
                this.sdkService.rmf.accessToken = accessToken;
                this.sdkService.cmf.accessToken = accessToken;
                this.sdkService.idf.accessToken = accessToken;
            });

        this.router.queryParams
            .pipe(
                takeUntil(this.destroy$),
                switchMap((params) => {
                    this.policyId = params.policyId;
                    this.behaviourId = params.behaviourId;
                    return forkJoin([
                        this.quoteService.getBehaviour(this.behaviourId),
                        this.policyService.getPolicy(this.policyId, this.behaviourId),
                    ]);
                })
            )
            .subscribe({
                next: async ([behaviour, policy]) => {
                    this.behaviour = behaviour;
                    this.behaviourService.set(behaviour);
                    this.clientPolicy = policy;
                    this.timezone = policy.timezone ?? 'UTC';

                    [this.paClient, this.originator] = await Promise.all([
                        this.sdkService.rmf.Clients.getById(this.clientPolicy.behaviour, this.clientPolicy.paClient),
                        this.sdkService.idf.Originators.get({ _id: policy.originator }).then(
                            (originators) => originators[0]
                        ),
                    ]);
                    this.organisation = this.originator.organisation as Organisation;

                    const documentLinks: Attachment[] = mapDocumentationLinks(this.clientPolicy.documentationLinks);
                    if (documentLinks) {
                        this.attachments = mapAttachments(documentLinks, this.behaviour, getTransactionType(policy));
                    }

                    if (this.clientPolicy.renewalDate) {
                        this.showInceptionDate = this.clientPolicy.inceptionDate >= this.clientPolicy.renewalDate;
                    }

                    this.policyDetails = {
                        inceptionDate: {
                            date: this.clientPolicy.inceptionDate,
                            timezone: this.timezone,
                            policyPeriod: this.behaviour.timePeriodConfig?.policyDateConfig?.inception,
                        },
                        renewalDate: {
                            date: this.clientPolicy.renewalDate,
                            timezone: this.timezone,
                            policyPeriod: this.behaviour.timePeriodConfig?.policyDateConfig?.inception,
                        },
                        expiryDate: {
                            date: this.clientPolicy.expiryDate,
                            timezone: this.timezone,
                            policyPeriod: this.behaviour.timePeriodConfig?.policyDateConfig?.expiry,
                        },
                        amendedDate: {
                            date: this.clientPolicy.amendedDate,
                            timezone: this.timezone,
                        },
                    };

                    this._updateServiceActionLinks();
                    this.loading = false;
                    this.error = false;
                },
                error: (err) => {
                    this.loading = false;
                    this.errorMessage = 'Policy ID is invalid';
                    this.error = true;
                },
            });
    }

    backToSearch() {
        this.location.back();
    }

    private _updateServiceActionLinks() {
        this.loadingAction = true;
        const { policyState, expiryDate } = this.clientPolicy;
        const invalidPolicyStates = [
            PolicyState.expired,
            PolicyState.cancelled,
            PolicyState.lapsed,
            PolicyState.expiringCancelled,
        ];
        const policyExpired = invalidPolicyStates.includes(policyState) || expiryDate < new Date().toISOString();

        this.authService.featureFlags
            .pipe(
                takeUntil(this.destroy$),
                switchMap((flags) => {
                    this.enabledFeatureFlags = flags.map((f) => f.type);
                    this.hideResendLink =
                        !this.enabledFeatureFlags.includes(FeatureFlags.insurerResendNotification) || policyExpired;

                    if (
                        this.enabledFeatureFlags.includes(FeatureFlags.insurerRegenerateDocuments) ||
                        this.enabledFeatureFlags.includes(FeatureFlags.reAssignBroker)
                    ) {
                        return from(
                            this.sdkService.cmf.ClientPolicies.getLatestRevision(
                                this.behaviourId,
                                this.clientPolicy._id,
                                false
                            )
                        );
                    } else {
                        return of(undefined);
                    }
                })
            )
            .subscribe({
                next: (latestPolicy) => {
                    if (
                        latestPolicy?._id.toString() === this.clientPolicy._id.toString() ||
                        (latestPolicy?.reference === this.clientPolicy.reference &&
                            latestPolicy?.referenceRevision === this.clientPolicy.referenceRevision &&
                            latestPolicy?.referenceRevisionRevision === this.clientPolicy.referenceRevisionRevision)
                    ) {
                        this.hideRegenerateLink =
                            !this.enabledFeatureFlags.includes(FeatureFlags.insurerRegenerateDocuments) ||
                            policyExpired;

                        this.hideReassignBrokerLink =
                            !this.enabledFeatureFlags.includes(FeatureFlags.reAssignBroker) ||
                            !(this.originator.type === OriginatorType.intermediary) ||
                            policyExpired;

                        this.hideProcessCancelLink =
                            !this.enabledFeatureFlags.includes(FeatureFlags.insurerForcePolicyCancellation) ||
                            policyExpired;
                    }
                    this.loadingAction = false;
                },
                error: (err) => {
                    this.loadingAction = false;
                },
            });
    }

    openRegenerateAcceptModal() {
        this.cancelStatus = false;
        this.serviceActionEvent = FeatureFlags.insurerRegenerateDocuments;
        this.confirmDialog.open(`Please confirm you want to Regenerate Policy Documentation`, 'Please Confirm!');
        return false;
    }

    openResendAcceptModal() {
        this.cancelStatus = false;
        this.serviceActionEvent = FeatureFlags.insurerResendNotification;
        this.confirmDialog.open(`Please confirm you want to Resend Policy Email.`, 'Please Confirm!');
        return false;
    }

    openProcessCancelModal() {
        this.cancelStatus = true;
        this.serviceActionEvent = FeatureFlags.insurerForcePolicyCancellation;
        this.confirmDialog.openCancelDialog(
            `${this.paClient.companyName} - ${this.clientPolicy.reference}`,
            this.clientPolicy
        );
        return false;
    }

    openReassignBrokerModal() {
        this.serviceActionEvent = FeatureFlags.reAssignBroker;
        if (this.organisationOriginators?.length === 0) {
            this.loadingDetails = true;
            this.sdkService.idf.Organisations.get({
                organisationId: this.organisation._id,
                originator: 'true',
                behaviour: this.behaviourId,
            }).then((organisation) => {
                this.organisationOriginators = (organisation[0].originators as unknown as Originator[])
                    .filter(
                        (originator) =>
                            originator.type === OriginatorType.intermediary ||
                            originator.type === OriginatorType.insuredPolicyManager
                    )
                    .sort((a, b) => (a.individual > b.individual ? 1 : -1));
                this.selectedOriginatorCode = this.organisationOriginators.find(
                    (o) => o.individual === this.originator.individual
                )?.code;
                this.loadingDetails = false;
            });
        }
        this.reassignBrokerDialog.open(`Please confirm who you'd like to Reassign this account to`, 'Reassign Broker');
        return false;
    }

    confirmReassignBroker() {
        this.confirmDialog.open('Once Reassigned, the Broker will automatically receive an updated Policy Email', '');
    }

    async acceptServiceAction(event: FeatureFlags) {
        this.loadingAction = true;
        let successMessage = 'Policy Email has successfully been resent';
        let errorMessage =
            'An error occurred when resending the Policy email - the platform support team has been notified and will be in touch.';
        let eventPromise: Promise<any>;

        if (event === FeatureFlags.insurerRegenerateDocuments) {
            eventPromise = this.sdkService.cmf.ClientPolicies.regenerateDocuments(
                this.behaviourId,
                TransactionType.amendment,
                this.clientPolicy._id
            );
        } else if (event === FeatureFlags.insurerResendNotification) {
            eventPromise = this.sdkService.cmf.ClientPolicies.notification(
                this.behaviourId,
                this.clientPolicy._id,
                this.clientPolicy.originator
            );
        } else if (event === FeatureFlags.reAssignBroker) {
            successMessage = 'Policy has been successfully reassigned';
            eventPromise = this.sdkService.idf.Originators.reassignBroker(
                this.behaviourId,
                this.selectedOriginatorCode,
                { clientPolicy: this.clientPolicy._id }
            );
        } else if (event === FeatureFlags.insurerForcePolicyCancellation) {
            this.currentUser = await Auth.currentUserInfo().then((data) => {
                return data.attributes.email;
            });
            const companyName = this.paClient.companyName;
            const cancelReason = [
                `cancellation of the policy '${companyName} - ${this.clientPolicy.reference}' is requested by ${this.currentUser}.`,
            ];
            eventPromise = this.sdkService.cmf.ClientPolicies.postPolicyCancelOffer(
                this.behaviourId,
                this.clientPolicy._id,
                this.cancellationDate,
                cancelReason
            );
            successMessage = `Successfully submit a cancel proposal`;
        }
        eventPromise
            .then(() => {
                this.loadingAction = false;
                this.responseDialog.open(successMessage);
            })
            .catch(() => {
                this.loadingAction = false;
                this.responseDialog.open(errorMessage);
            });
    }

    closeClicked() {
        return window.location.reload();
    }

    cancellationEvent(date: string) {
        this.cancellationDate = date;
    }
}
